import { useEffect, useState } from 'react'

import { ArrowForward, AttachMoney } from '@mui/icons-material'
import { Modal } from '@mui/material'
import { addDays, format, getHours } from 'date-fns'
import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'

import { firestore } from 'main/firebase'
import { currencyFormatter } from 'main/functions'

import { globalSettings } from '../../../../globalSettingsReact'
import img1 from '../../../../main/assets/money.png'
import img2 from '../../../../main/assets/money2.png'
import img3 from '../../../../main/assets/money3.png'
import img4 from '../../../../main/assets/money4.png'
import img5 from '../../../../main/assets/money5.png'
import ParticipantsModal from '../ParticipantsModal'
import PaymentModal from './components/PaymentModal'
import {
  Amount,
  Container,
  Currency,
  DateElement,
  FloatingIcon,
  Header,
  Icon,
  Note,
  NoteLink,
  Observation,
  StyledButton,
  Title,
  TotalOfParticipants,
  Value
} from './styles'

function RaffleBox() {
  const [showModalRaffle, setShowModalRaffle] = useState(false)
  const [showModalParticipants, setShowModalParticipants] = useState(false)
  const [totalOfParticipants, setTotalOfParticipants] = useState(0)
  const [raffleDay, setRaffleDay] = useState<Date>(new Date())

  const handleParticipate = () => {
    setShowModalParticipants(false)
    setShowModalRaffle(true)
  }

  const fetchRaffle = async () => {
    const date = new Date()

    if (getHours(date) >= 18) {
      setRaffleDay(addDays(new Date(), 1))
    }

    const raffleRef = query(
      collection(firestore, 'raffle'),
      where('status', '==', 'OPEN'),
      orderBy('createdAt', 'desc'),
      limit(1)
    )

    const currentRaffle = await getDocs(raffleRef)

    const subCollections = await query(collection(firestore, 'raffle', currentRaffle?.docs[0]?.id, 'participants'))

    onSnapshot(subCollections, snapshot => {
      if (!snapshot.empty) {
        setTotalOfParticipants(snapshot.size)
      }
    })
  }

  useEffect(() => {
    fetchRaffle()
  }, [])

  const totalRaisedAmount =
    totalOfParticipants * globalSettings.contribuitionAmountPerUser + globalSettings.starterAmount

  const participantsCount = <>{totalOfParticipants} participante(s)</>

  const beTheFirst = <button>Seja o primeiro a participar</button>

  return (
    <Container>
      <FloatingIcon number={1}>
        <img alt='icone' src={img1} />
      </FloatingIcon>
      <FloatingIcon number={2}>
        <img alt='icone' src={img2} />
      </FloatingIcon>
      <FloatingIcon number={3}>
        <img alt='icone' src={img3} />
      </FloatingIcon>
      <FloatingIcon number={4}>
        <img alt='icone' src={img4} />
      </FloatingIcon>
      <FloatingIcon number={5}>
        <img alt='icone' src={img5} />
      </FloatingIcon>
      <Header>
        <Icon>
          <AttachMoney />
        </Icon>
        <Title>Com apenas R$ {currencyFormatter(globalSettings.ticketPrice)} concorra a...</Title>
        <Amount>
          <Currency>R$</Currency>
          {/* <Value>{currencyFormatter(totalRaisedAmount)}</Value> */}
          <Value>{currencyFormatter(globalSettings.thresholdAmount)}</Value>
        </Amount>
        <TotalOfParticipants onClick={() => setShowModalParticipants(true)}>
          {/* {totalOfParticipants ? participantsCount : participantsCount} */}
          Pote acumulado: R$ {currencyFormatter(totalRaisedAmount)}
        </TotalOfParticipants>
      </Header>
      <DateElement>
        Data do sorteio <br /> {format(raffleDay, 'dd/MM/yyyy')} as 18:00 horas <br />
        <small>(horário de Brasília)</small>
        <br />
      </DateElement>
      <Observation>
        Caso o sorteio não alcance o valor mínimo de <b>R$ {currencyFormatter(globalSettings.thresholdAmount)}</b>,{' '}
        <br /> o valor acumulará e será sorteado no próximo dia.
      </Observation>
      <StyledButton onClick={() => setShowModalRaffle(true)}>
        Participe do sorteio <ArrowForward />
      </StyledButton>
      <Note>
        Clicando em &apos;Participe do Sorteio&apos; você está concordando com os{' '}
        <NoteLink to='/termos'>termos de uso</NoteLink>
      </Note>

      <Modal open={showModalRaffle} onClose={() => setShowModalRaffle(false)} style={{ overflowY: 'auto' }}>
        <PaymentModal handleClose={() => setShowModalRaffle(false)} />
      </Modal>
      <Modal open={showModalParticipants} onClose={() => setShowModalParticipants(false)} style={{ overflowY: 'auto' }}>
        <ParticipantsModal handleClose={() => setShowModalParticipants(false)} handleParticipate={handleParticipate} />
      </Modal>
    </Container>
  )
}

export default RaffleBox
